<script setup>
import { Auth, UserContextProvider } from '@supa-kit/auth-ui-vue'
import { ThemeSupa } from '@supabase/auth-ui-shared'

defineOptions({
  inheritAttrs: false,
})

const supabaseClient = useSupabaseClient()
</script>

<template>
  <UCard :ui="{ ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
    <template #header>
      <div class="text-center">
        <h3 class="text-base font-semibold leading-6 text-gray-900 dark:text-white">
          Sign In
        </h3>
      </div>
    </template>
    <button aria-hidden="true" class="absolute" /> <!-- To not focus the first body -->
    <UserContextProvider :supabase-client="supabaseClient">
      <Auth
        :supabase-client="supabaseClient"
        :providers="[]"
        :appearance="{
          theme: ThemeSupa,
          dark: true,
          variables: {
            default: {
              fontSizes: {
                baseInputSize: '16px',
              },
              colors: {
                brand: 'rgb(var(--color-primary-600 )/0.8)',
                brandAccent: 'rgb(var(--color-primary-600 ))',
              },
            },
          },
        }"
        theme="dark"
        :to="useRouter().currentRoute.value.fullPath"
      />
    </UserContextProvider>
  </UCard>
</template>
