const percRangePosrank = {
  QB: [0, 24],
  RB: [0, 30],
  WR: [0, 50],
  TE: [0, 16],
  FB: [0, 12],
  PK: [0, 36],
  DPK: [0, 36],
  K: [0, 0],
}

const positionsOrder: Record<string, number> = {
  QB: 1,
  RB: 2,
  WR: 3,
  TE: 4,
  FB: 5,
  PK: 6,
  DPK: 7,
  K: 8,
}

const percRanges = {
  adp_adp: [0, 120],
  adp_rank: [0, 120],
  ud_adp: [0, 180],
  ud_adp_rank: [0, 180],
  dk_adp: [0, 180],
  dk_adp_rank: [0, 180],
  diff_adp1: [0, 180],
  diff_adp2: [0, 180],
  diff_adp_rank1: [0, 180],
  diff_adp_rank2: [0, 180],
  draft_rank: [0, 120],
  draft_year: [2014, 2025],
  draft_round: [1, 5],
  phy_age: {
    QB: [23, 36],
    RB: [21, 29],
    WR: [21, 31],
    TE: [21, 31],
    FB: [21, 29],
    PK: [0, 0],
    DPK: [0, 0],
    K: [0, 0],
  },
  adp_posrank: percRangePosrank,
  ud_adp_posrank: percRangePosrank,
  dk_adp_posrank: percRangePosrank,
  diff_adp_posrank1: percRangePosrank,
  diff_adp_posrank2: percRangePosrank,
  draft_posrank: percRangePosrank,
}

const percDiffRanges = new Map([
  ['elite', {
    minValue: 0.8,
    percRange: {
      adp_adp: [-2, 2],
      ud_adp: [-2, 2],
      adp_rank: [-1, 1],
      ud_adp_rank: [-1, 1],
      adp_posrank: [-1, 1],
      ud_adp_posrank: [-1, 1],
    },
  }],
  ['great', {
    minValue: 0.5,
    percRange: {
      adp_adp: [-2, 2],
      ud_adp: [-2, 2],
      adp_rank: [-2, 2],
      ud_adp_rank: [-2, 2],
      adp_posrank: [-2, 2],
      ud_adp_posrank: [-2, 2],
    },
  }],
  ['average', {
    minValue: 0.2,
    percRange: {
      adp_adp: [-3, 3],
      ud_adp: [-3, 3],
      adp_rank: [-3, 3],
      ud_adp_rank: [-3, 3],
      adp_posrank: [-2, 2],
      ud_adp_posrank: [-2, 2],
    },
  }],
  ['base', {
    minValue: 0,
    percRange: {
      adp_adp: [-6, 6],
      ud_adp: [-6, 6],
      adp_rank: [-6, 6],
      ud_adp_rank: [-6, 6],
      adp_posrank: [-3, 3],
      ud_adp_posrank: [-3, 3],
    },
  }],
])

const metricPercRangeMap = new Map<string, iPercRange>([
  ['adp_adp', { type: 'basic', reverse: true, range: [0, 120] }],
  ['ud_adp', { type: 'basic', reverse: true, range: [0, 180] }],
  ['dk_adp', { type: 'basic', reverse: true, range: [0, 180] }],
  ['diff_adp1', { type: 'basic', reverse: true, range: [0, 180] }],
  ['diff_adp2', { type: 'basic', reverse: true, range: [0, 180] }],
  ['adp_rank', { type: 'basic', reverse: true, range: [0, 120] }],
  ['ud_adp_rank', { type: 'basic', reverse: true, range: [0, 180] }],
  ['dk_adp_rank', { type: 'basic', reverse: true, range: [0, 180] }],
  ['diff_adp_rank1', { type: 'basic', reverse: true, range: [0, 180] }],
  ['diff_adp_rank2', { type: 'basic', reverse: true, range: [0, 180] }],
  ['draft_rank', { type: 'basic', reverse: true, range: [0, 120] }],
  ['adp_posrank', { type: 'positional', reverse: true, range: { QB: [0, 24], RB: [0, 30], WR: [0, 50], TE: [0, 16], PK: [0, 36], DPK: [0, 36], K: [0, 36], FB: [0, 12] } }],
  ['ud_adp_posrank', { type: 'positional', reverse: true, range: { QB: [0, 24], RB: [0, 30], WR: [0, 50], TE: [0, 16], PK: [0, 36], DPK: [0, 36], K: [0, 36], FB: [0, 12] } }],
  ['dk_adp_posrank', { type: 'positional', reverse: true, range: { QB: [0, 24], RB: [0, 30], WR: [0, 50], TE: [0, 16], PK: [0, 36], DPK: [0, 36], K: [0, 36], FB: [0, 12] } }],
  ['diff_adp_posrank1', { type: 'positional', reverse: true, range: { QB: [0, 24], RB: [0, 30], WR: [0, 50], TE: [0, 16], PK: [0, 36], DPK: [0, 36], K: [0, 36], FB: [0, 12] } }],
  ['diff_adp_posrank2', { type: 'positional', reverse: true, range: { QB: [0, 24], RB: [0, 30], WR: [0, 50], TE: [0, 16], PK: [0, 36], DPK: [0, 36], K: [0, 36], FB: [0, 12] } }],
  ['draft_posrank', { type: 'positional', reverse: true, range: { QB: [0, 24], RB: [0, 30], WR: [0, 50], TE: [0, 16], PK: [0, 36], DPK: [0, 36], K: [0, 36], FB: [0, 12] } }],
  ['ud_projection_ppg', { type: 'positional', reverse: false, range: { QB: [15, 21], RB: [5, 18], WR: [5, 18], TE: [6, 12], PK: [0, 36], DPK: [0, 36], K: [0, 36], FB: [0, 12] } }],
  ['adp_adp_diff', { type: 'gradual', baseMetric: 'adp_adp_perc', range: [{ minValue: 0.8, range: [-2, 2] }, { minValue: 0.5, range: [-2, 2] }, { minValue: 0.2, range: [-3, 3] }, { minValue: 0, range: [-6, 6] }] }],
  ['diff_adp_diff', { type: 'gradual', baseMetric: 'adp_adp_perc', range: [{ minValue: 0.8, range: [-2, 2] }, { minValue: 0.5, range: [-2, 2] }, { minValue: 0.2, range: [-3, 3] }, { minValue: 0, range: [-6, 6] }] }],
  ['adp_rank_diff', { type: 'gradual', baseMetric: 'adp_rank_perc', range: [{ minValue: 0.8, range: [-1, 1] }, { minValue: 0.5, range: [-2, 2] }, { minValue: 0.2, range: [-3, 3] }, { minValue: 0, range: [-6, 6] }] }],
  ['diff_adp_diff_rank', { type: 'gradual', baseMetric: 'adp_rank_perc', range: [{ minValue: 0.8, range: [-1, 1] }, { minValue: 0.5, range: [-2, 2] }, { minValue: 0.2, range: [-3, 3] }, { minValue: 0, range: [-6, 6] }] }],
  ['adp_posrank_diff', { type: 'gradual', baseMetric: 'adp_posrank_perc', range: [{ minValue: 0.8, range: [-1, 1] }, { minValue: 0.5, range: [-2, 2] }, { minValue: 0.2, range: [-2, 2] }, { minValue: 0, range: [-3, 3] }] }],
  ['diff_adp_diff_posrank', { type: 'gradual', baseMetric: 'adp_posrank_perc', range: [{ minValue: 0.8, range: [-1, 1] }, { minValue: 0.5, range: [-2, 2] }, { minValue: 0.2, range: [-2, 2] }, { minValue: 0, range: [-3, 3] }] }],
  ['phy_age', { type: 'positional', range: { QB: [23, 36], RB: [21, 29], WR: [21, 31], TE: [21, 31], PK: [0, 0], DPK: [0, 0], K: [0, 36], FB: [21, 29] } }],
  ['draft_year', { type: 'basic', range: [2014, 2025] }],
  ['draft_round', { type: 'basic', range: [1, 5] }],
])

function getPercRangeMapped<T extends iPlayer>(item: T, def: iPercRange) {
  const { type, range } = def
  if (type === 'basic')
    return range
  if (type === 'positional')
    return getPercPositionalRange(item, range)
  if (type === 'gradual')
    return getPercGradualRange(item, def)
  return [0, 0]
}

export function getPercValueMetric<T extends iPlayer, K extends keyof T & string>(item: T, metric: K) {
  const def = metricPercRangeMap.get(metric)
  if (!def)
    return
  const percRange = getPercRangeMapped(item, def)

  const value = convertStringToNumber(item[metric])
  return (typeof value === 'number' && Array.isArray(percRange)) ? normalizeValue(value, percRange[0], percRange[1], def.reverse) : undefined
}

export function addPercentileAtributes<T extends iPlayer, K extends keyof T & string>(dataset: T[], metrics: K[]) {
  for (const item of dataset) {
    for (const metric of metrics) {
      const percMetric = `${metric}_perc` as K
      const percValue = getPercValueMetric(item, metric)
      if (typeof percValue === 'number')
        safeAssign(item, percMetric, roundNumber(percValue, 2))
    }
  }
}

function getPercPositionalRange<T extends iPlayer>(item: T, definition: Record<string, number[]>) {
  const position = item?.id_pos || 'PK'
  return definition[position]
}

function getPercGradualRange<T extends iPlayer>(item: T, { baseMetric, range }: iPercRangeGradual) {
  if (!(baseMetric in item))
    return [0, 0]
  const value = item[baseMetric as keyof T]
  if (typeof value !== 'number')
    return [0, 0]
  for (const { minValue, range: newRange } of range) {
    if (value >= minValue)
      return newRange
  }
  return [0, 0]
}

export function getPercRange(oldNormalizedValue: number, type: 'adp_adp' | 'adp_rank' | 'adp_posrank' | 'ud_adp' | 'ud_adp_rank' | 'ud_adp_posrank') {
  for (const [_name, range] of percDiffRanges) {
    if (oldNormalizedValue >= range.minValue)
      return range.percRange[type]
  }
  return [0, 0]
}

export function getAdpDiffDefaultValues<T extends iPlayer>(oldData: T[], newDataSize: number) {
  const defaultValues = {
    adp_adp: newDataSize,
    adp_rank: 0,
    adp_posrank: { QB: 0, RB: 0, WR: 0, TE: 0, PK: 0, DPK: 0, K: 0, FB: 0 },
  }
  for (const player of oldData) {
    defaultValues.adp_rank = Math.max(defaultValues.adp_rank, player.adp_rank || 0)
    const position = (player?.id_pos || 'PK') as iPlayerPositionList
    if (player.id_pos && defaultValues.adp_posrank[position])
      defaultValues.adp_posrank[position] = Math.max(defaultValues.adp_posrank[position], player.adp_posrank || 0)
  }
  return defaultValues
}

export function patchAdpMetrics<T extends iPlayer>(data: T[], metrics: (keyof T & string)[] = ['adp_adp', 'adp_posrank', 'adp_rank', 'draft_year', 'draft_round', 'phy_age']) {
  addAdpAttributes(data)
  addPercentileAtributes(data, metrics)
}

export function patchUdAdpMetrics<T extends iPlayer>(data: T[], metrics: (keyof T & string)[] = ['ud_adp', 'ud_adp_posrank', 'ud_adp_rank', 'ud_projection_ppg', 'draft_year', 'draft_round', 'phy_age']) {
  addUdAdpAttributes(data)
  addPercentileAtributes(data, metrics)
}

export function patchDkAdpMetrics<T extends iPlayer>(data: T[], metrics: (keyof T & string)[] = ['dk_adp', 'dk_adp_posrank', 'dk_adp_rank', 'draft_year', 'draft_round', 'phy_age']) {
  addDkAdpAttributes(data)
  addPercentileAtributes(data, metrics)
}

export function addDkAdpAttributes<T extends iPlayer>(data: T[]) {
  const posRankMap = new Map<string, number>()
  const sortedData = [...data].sort(sortByAttribute('dk_adp'))
  const attributesMap = new Map()

  for (const [index, player] of sortedData.entries()) {
    const position = player?.id_pos || 'PK'
    const currentPosRank = posRankMap.get(position) || 0
    posRankMap.set(position, currentPosRank + 1)

    const nameData = {
      id_name: getPlayerName(player),
    }
    const dk_adp = Number(player?.dk_adp) || 216
    const adpDkData = {
      dk_adp,
      dk_adp_value: MapDictionary.BbAdpValues.get(roundNumber(dk_adp, 0)),
      dk_adp_rank: index + 1,
      dk_adp_posrank: currentPosRank + 1,
    }
    const adpLabels = genAdpDkLabels(adpDkData, position)
    attributesMap.set(player.id_pff, { ...nameData, ...adpDkData, ...adpLabels })
  }
  for (const player of data)
    Object.assign(player, attributesMap.get(player.id_pff))
}

export function addUdAdpAttributes<T extends iPlayer>(data: T[]) {
  const posRankMap = new Map<string, number>()
  const sortedData = [...data].sort(sortByAttribute('ud_adp'))
  const attributesMap = new Map()

  for (const [index, player] of sortedData.entries()) {
    const position = player?.id_pos || 'PK'
    const currentPosRank = posRankMap.get(position) || 0
    posRankMap.set(position, currentPosRank + 1)

    const nameData = {
      id_name: getPlayerName(player),
    }
    const ud_adp = Number(player?.ud_adp) || 216
    const adpUdData = {
      ud_adp,
      ud_adp_value: MapDictionary.BbAdpValues.get(roundNumber(ud_adp, 0)),
      ud_adp_rank: index + 1,
      ud_adp_posrank: currentPosRank + 1,
    }
    const adpLabels = genAdpUdLabels(adpUdData, position)
    attributesMap.set(player.id_pff, { ...nameData, ...adpUdData, ...adpLabels })
  }
  for (const player of data)
    Object.assign(player, attributesMap.get(player.id_pff))
}

export function addPositionOrder<T extends iPlayer>(player: T) {
  return positionsOrder[player?.id_pos || 'PK'] || 9
}

export function addAdpAttributes<T extends iPlayer>(data: T[]) {
  const posRankMap = new Map<string, number>()
  const sortedData = [...data].sort(sortByAttribute('adp_adp'))
  const attributesMap = new Map()

  for (const [index, player] of sortedData.entries()) {
    const position = player?.id_pos || 'PK'
    const currentPosRank = posRankMap.get(position) || 0
    posRankMap.set(position, currentPosRank + 1)

    const nameData = {
      id_name: getPlayerName(player),
    }
    const adpData = {
      adp_adp: Number(player?.adp_adp) || 300,
      adp_rank: index + 1,
      adp_posrank: currentPosRank + 1,
    }
    const adpLabels = genAdpLabels(adpData, position)
    attributesMap.set(player.id_pff, { ...nameData, ...adpData, ...adpLabels })
  }
  for (const player of data)
    Object.assign(player, attributesMap.get(player.id_pff))
}

export function genPickLabelNumbers(value: number, teams: number = 12) {
  const roundNum = Math.ceil(value / teams)
  const teamNum = String(value % teams || teams).padStart(2, '0')
  return { roundNum, teamNum }
}

export function genLabelAdp(value: number | undefined, teams = 12) {
  if (!value)
    return value === 0 ? '1.01' : '-'
  const { roundNum, teamNum } = genPickLabelNumbers(value, teams)
  return `${roundNum}.${teamNum}`
}

function genAdpLabels({ adp_adp, adp_rank, adp_posrank }: { adp_adp: number, adp_rank: number, adp_posrank: number }, position: string, teams: number = 12) {
  return {
    adp_rank_label: genLabelAdp(adp_rank, teams) ?? '-',
    adp_adp_label: adp_adp > 100 ? Math.round(adp_adp).toString() : adp_adp?.toFixed(1) ?? '-',
    adp_posrank_label: `${position}${adp_posrank ?? '-'}`,
    // adp_adp_perc: normalizeValue(adp_adp, percRanges.adp_adp[0], percRanges.adp_adp[1], true),
    // adp_rank_perc: normalizeValue(adp_rank, percRanges.adp_rank[0], percRanges.adp_rank[1], true),
    // adp_posrank_perc: normalizeValue(adp_posrank, percRanges.adp_posrank[position][0], percRanges.adp_posrank[position][1], true),
  }
}

function genAdpDkLabels({ dk_adp, dk_adp_rank, dk_adp_posrank }: { dk_adp: number, dk_adp_rank: number, dk_adp_posrank: number }, position: string, teams: number = 12) {
  return {
    dk_adp_rank_label: genLabelAdp(dk_adp_rank, teams) ?? '-',
    dk_adp_label: dk_adp > 100 ? Math.round(dk_adp).toString() : dk_adp?.toFixed(1) ?? '-',
    dk_adp_posrank_label: `${position}${dk_adp_posrank ?? '-'}`,
  }
}

function genAdpUdLabels({ ud_adp, ud_adp_rank, ud_adp_posrank }: { ud_adp: number, ud_adp_rank: number, ud_adp_posrank: number }, position: string, teams: number = 12) {
  return {
    ud_adp_rank_label: genLabelAdp(ud_adp_rank, teams) ?? '-',
    ud_adp_label: ud_adp > 100 ? Math.round(ud_adp).toString() : ud_adp?.toFixed(1) ?? '-',
    ud_adp_posrank_label: `${position}${ud_adp_posrank ?? '-'}`,
  }
}

export function genAdpDifference(oldData: iPlayer[], newData: iPlayer[]) {
  const oldDataMap = new Map(oldData.map(player => [player.id_sleeper, player]))
  const newDataMap = new Map(newData.map(player => [player.id_sleeper, player]))
  const playerIds = new Set([...oldDataMap.keys(), ...newDataMap.keys()])
  const defaultValues = getAdpDiffDefaultValues(oldData, newDataMap.size)
  return [...playerIds].map(id => getAdpDiff(id, oldDataMap, newDataMap, defaultValues))
}

function getMetricDiff<T extends Record<string, any>>(metric: keyof T, oldItem?: T, newItem?: T, defaultValue = 0, round: number = 2) {
  const oldValue = roundNumber((oldItem?.[metric]) || defaultValue, round)
  const newValue = roundNumber((newItem?.[metric]) || defaultValue, round)
  const diff = getDiffValue(oldValue, newValue, round)
  return { old: oldValue, new: newValue, diff }
}

function getAdpDiff<T extends iPlayer>(id: string, oldDataMap: Map<string, T>, newDataMap: Map<string, T>, defaultValues: ReturnType<typeof getAdpDiffDefaultValues>) {
  const oldPlayer = oldDataMap.get(id)
  const newPlayer = newDataMap.get(id)
  const position = (newPlayer?.id_pos ?? oldPlayer?.id_pos ?? 'PK') as iPlayerPositionList
  const { new: adp_adp, old: adp_adp_old, diff: adp_adp_diff } = getMetricDiff('adp_adp', oldPlayer, newPlayer, defaultValues.adp_adp)
  const { new: adp_rank, old: adp_rank_old, diff: adp_rank_diff } = getMetricDiff('adp_rank', oldPlayer, newPlayer, defaultValues.adp_rank)
  const { new: adp_posrank, old: adp_posrank_old, diff: adp_posrank_diff } = getMetricDiff('adp_posrank', oldPlayer, newPlayer, defaultValues.adp_posrank[position])
  const adp_adp_perc = roundNumber(normalizeValue(adp_adp, percRanges.adp_adp[0], percRanges.adp_adp[1], true), 2)
  const adp_adp_old_perc = roundNumber(normalizeValue(adp_adp_old, percRanges.adp_adp[0], percRanges.adp_adp[1], true), 2)
  const adp_rank_perc = roundNumber(normalizeValue(adp_rank, percRanges.adp_rank[0], percRanges.adp_rank[1], true), 2)
  const adp_rank_old_perc = roundNumber(normalizeValue(adp_rank_old, percRanges.adp_rank[0], percRanges.adp_rank[1], true), 2)
  const adp_posrank_perc = roundNumber(normalizeValue(adp_posrank, percRanges.adp_posrank[position][0], percRanges.adp_posrank[position][1], true), 2)
  const adp_posrank_old_perc = roundNumber(normalizeValue(adp_posrank_old, percRanges.adp_posrank[position][0], percRanges.adp_posrank[position][1], true), 2)
  const adp_adp_diff_range = getPercRange(adp_adp_old_perc, 'adp_adp')
  const adp_rank_diff_range = getPercRange(adp_adp_old_perc, 'adp_rank')
  const adp_posrank_diff_range = getPercRange(adp_adp_old_perc, 'adp_posrank')
  const adp_adp_diff_perc = normalizeValue(adp_adp_diff, adp_adp_diff_range[0], adp_adp_diff_range[1])
  const adp_rank_diff_perc = normalizeValue(adp_rank_diff, adp_rank_diff_range[0], adp_rank_diff_range[1])
  const adp_posrank_diff_perc = normalizeValue(adp_posrank_diff, adp_posrank_diff_range[0], adp_posrank_diff_range[1])

  return {
    ...oldPlayer,
    ...newPlayer,
    adp_adp,
    adp_adp_perc,
    adp_adp_old,
    adp_adp_old_perc,
    adp_adp_diff,
    adp_adp_diff_perc,
    adp_rank,
    adp_rank_perc,
    adp_rank_old,
    adp_rank_old_perc,
    adp_rank_diff,
    adp_rank_diff_perc,
    adp_posrank,
    adp_posrank_perc,
    adp_posrank_old,
    adp_posrank_old_perc,
    adp_posrank_diff,
    adp_posrank_diff_perc,
  }
}

export function getPlayersTeam(dataset: iPlayer[], teamNumber: number, totalTeams: number, sortingType: iPlayerDisplaySortingType, rounds: number, metric: iPlayerStringOrigin = 'id_sleeper') {
  const indexes: number[] = []
  for (let round = 0; round < rounds; round++)
    indexes.push((round * totalTeams) + calculateOffset(sortingType, teamNumber, totalTeams, round))
  return indexes.map(i => dataset[i]?.[metric]).filter(Boolean) as string[]
}

export function calculateOffset(sortingType: iPlayerDisplaySortingType, teamNumber: number, totalTeams: number, round: number): number {
  switch (sortingType) {
    case 'linear': {
      return teamNumber - 1
    }
    case 'snake': {
      return round % 2 === 0 ? teamNumber - 1 : totalTeams - teamNumber
    }
    case 'rr': {
      if (round < 2)
        return round % 2 === 0 ? teamNumber - 1 : totalTeams - teamNumber
      else if (round === 2)
        return totalTeams - teamNumber
      else
        return (round % 2 === 0) ? totalTeams - teamNumber : teamNumber - 1
    }
  // No default
  }
}

export function movePlayerToFront(arr: iPlayer[], playerId: string): iPlayer[] {
  const playerIndex = arr.findIndex(player => player.id_pff === playerId)
  if (playerIndex !== -1) {
    const playerToMove = arr[playerIndex]
    return [playerToMove, ...arr.slice(0, playerIndex), ...arr.slice(playerIndex + 1)]
  }
  return arr
}

export function getPercColor(value?: number, reverse = false): [number, number, number] {
  if (!value)
    return [204, 204, 204]
  const percentile = Math.round(value <= 0 || value >= 1 ? value : value * 100)
  const adjustedPercentile = reverse ? 100 - percentile : percentile
  return MapDictionary.percentileColor.get(adjustedPercentile) || [204, 204, 204]
}

export function extractAdpDiffValues(player: iPlayer, pick_no: number) {
  const adp_value: number = MapDictionary.BbAdpValues.get(roundNumber(player?.adp_adp || 240, 0))
  const pick_value: number = MapDictionary.BbAdpValues.get(pick_no)
  const adp_diff_value = roundNumber(pick_value - adp_value, 1)
  const adp_diff = player?.adp_adp ? roundNumber(pick_no - player.adp_adp, 1) : undefined
  const adp_diff_perc = adp_diff ? normalizeNumber(adp_diff, -6, 6) : 0
  const adp_diff_value_perc = normalizeNumber(adp_diff_value, -6, 6)
  const pick_no_perc = normalizeNumber(pick_no, 0, 120)
  return { adp_value, pick_value, adp_diff_value, adp_diff, pick_no, adp_diff_value_perc, adp_diff_perc, pick_no_perc }
}

export async function fetchDraftPlayers(draftId: string) {
  return draftId.length <= 6
    ? await useBppSimpleFetch<Array<{ player_id: string, pick_no: number, user_id: string, metadata: { position: string } }>>(`/mfl/draftPicks`, { query: { league: draftId } }, { errors: false, auth: true })
    : await getDraftPickData(draftId)
}
