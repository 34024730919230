
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as _91id_93zngLs5WzhxMeta } from "/opt/buildhome/repo/pages/adp/[id].vue?macro=true";
import { default as _91id_93ppZiX1Ty87Meta } from "/opt/buildhome/repo/pages/adp/companion/[id].vue?macro=true";
import { default as index0tvj6q15iJMeta } from "/opt/buildhome/repo/pages/adp/companion/index.vue?macro=true";
import { default as compareBTJ3ePNVSXMeta } from "/opt/buildhome/repo/pages/adp/compare.vue?macro=true";
import { default as comparePkHQCBdggSMeta } from "/opt/buildhome/repo/pages/adp/diff/compare.vue?macro=true";
import { default as indexDQwvoQdbBuMeta } from "/opt/buildhome/repo/pages/adp/diff/index.vue?macro=true";
import { default as draftfl9fbDYDBXMeta } from "/opt/buildhome/repo/pages/adp/draft.vue?macro=true";
import { default as hdraft34x77j4aTAMeta } from "/opt/buildhome/repo/pages/adp/hdraft.vue?macro=true";
import { default as indexSbCgmDNzI1Meta } from "/opt/buildhome/repo/pages/adp/index.vue?macro=true";
import { default as rookie_heatmap7FBQVxbEYbMeta } from "/opt/buildhome/repo/pages/adp/rookie_heatmap.vue?macro=true";
import { default as sfb_rosters1WqcFlrxPZMeta } from "/opt/buildhome/repo/pages/adp/sfb_rosters.vue?macro=true";
import { default as sfbW88JVrvJCvMeta } from "/opt/buildhome/repo/pages/adp/sfb.vue?macro=true";
import { default as adp6JABZoR7sbMeta } from "/opt/buildhome/repo/pages/bestball/draftkings/adp.vue?macro=true";
import { default as indexf6Oa6XijlXMeta } from "/opt/buildhome/repo/pages/bestball/index.vue?macro=true";
import { default as adpyQrJUwsHAYMeta } from "/opt/buildhome/repo/pages/bestball/underdog/adp.vue?macro=true";
import { default as rosterGridd4sF3sSfkCMeta } from "/opt/buildhome/repo/pages/bestball/underdog/rosterGrid.vue?macro=true";
import { default as indexOjLyWPx0yZMeta } from "/opt/buildhome/repo/pages/bestball/underdog/rostership/index.vue?macro=true";
import { default as stackszcdfD64sknMeta } from "/opt/buildhome/repo/pages/bestball/underdog/rostership/stacks.vue?macro=true";
import { default as useroWSaA6Fl0uMeta } from "/opt/buildhome/repo/pages/bestball/underdog/rostership/user.vue?macro=true";
import { default as _91_46_46_46slug_93iiBzaDIhzjMeta } from "/opt/buildhome/repo/pages/blog/[...slug].vue?macro=true";
import { default as indexnCitm8tluDMeta } from "/opt/buildhome/repo/pages/blog/index.vue?macro=true";
import { default as confirmqkqxgiK386Meta } from "/opt/buildhome/repo/pages/confirm.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as playerlistqwwIGloryDMeta } from "/opt/buildhome/repo/pages/playerlist.vue?macro=true";
import { default as _91id_93uGuSFTQCORMeta } from "/opt/buildhome/repo/pages/rankings/edit/[id].vue?macro=true";
import { default as indexEcLBzCs9kDMeta } from "/opt/buildhome/repo/pages/rankings/edit/index.vue?macro=true";
import { default as indexZRMySY0IXbMeta } from "/opt/buildhome/repo/pages/rankings/index.vue?macro=true";
import { default as _91type_9377Q4qIbNpQMeta } from "/opt/buildhome/repo/pages/rankings/new/[type].vue?macro=true";
import { default as indexpjG6xvESeoMeta } from "/opt/buildhome/repo/pages/rankings/new/index.vue?macro=true";
import { default as _91id_93NclW2X0lqzMeta } from "/opt/buildhome/repo/pages/rankings/view/[id].vue?macro=true";
import { default as indexmSmmdT6hRVMeta } from "/opt/buildhome/repo/pages/rankings/view/index.vue?macro=true";
import { default as releasestmyHtXKZrkMeta } from "/opt/buildhome/repo/pages/releases.vue?macro=true";
import { default as gamesD8bed3utjzMeta } from "/opt/buildhome/repo/pages/rosters/games.vue?macro=true";
import { default as indexQCNzS30N5CMeta } from "/opt/buildhome/repo/pages/rosters/index.vue?macro=true";
import { default as lineups1d1KnvwjMKMeta } from "/opt/buildhome/repo/pages/rosters/lineups.vue?macro=true";
import { default as playersIIDDbF99AxMeta } from "/opt/buildhome/repo/pages/rosters/players.vue?macro=true";
import { default as byet3bnrfTxPtMeta } from "/opt/buildhome/repo/pages/rosters/suggestions/bye.vue?macro=true";
import { default as flexPtdPbvVo10Meta } from "/opt/buildhome/repo/pages/rosters/suggestions/flex.vue?macro=true";
import { default as injury9kbdYKodpBMeta } from "/opt/buildhome/repo/pages/rosters/suggestions/injury.vue?macro=true";
import { default as startLiZiS83iQDMeta } from "/opt/buildhome/repo/pages/rosters/suggestions/start.vue?macro=true";
import { default as testMPkUTlm5CAMeta } from "/opt/buildhome/repo/pages/rosters/test.vue?macro=true";
import { default as ownershipJAeKP3gX4EMeta } from "/opt/buildhome/repo/pages/rosters/waivers/ownership.vue?macro=true";
import { default as trendingbwEsSBFFZ8Meta } from "/opt/buildhome/repo/pages/rosters/waivers/trending.vue?macro=true";
import { default as index9h9gSglSmHMeta } from "/opt/buildhome/repo/pages/stats/index.vue?macro=true";
import { default as player8NYvYHRLMTMeta } from "/opt/buildhome/repo/pages/stats/player.vue?macro=true";
import { default as rookiegVIgND45QUMeta } from "/opt/buildhome/repo/pages/stats/rookie.vue?macro=true";
import { default as teamg18ikPK0ATMeta } from "/opt/buildhome/repo/pages/stats/team.vue?macro=true";
import { default as cancelfDfNBqrTw1Meta } from "/opt/buildhome/repo/pages/subscription/cancel.vue?macro=true";
import { default as indexaH9PDt97YyMeta } from "/opt/buildhome/repo/pages/subscription/index.vue?macro=true";
import { default as successy3ZIKizgcFMeta } from "/opt/buildhome/repo/pages/subscription/success.vue?macro=true";
import { default as playerAZFRxHpl85Meta } from "/opt/buildhome/repo/pages/trades/player.vue?macro=true";
import { default as indexWdgqHq4sX0Meta } from "/opt/buildhome/repo/pages/trades/rostership/index.vue?macro=true";
import { default as userxCy1ne5msRMeta } from "/opt/buildhome/repo/pages/trades/rostership/user.vue?macro=true";
import { default as usercwLTbaiEanMeta } from "/opt/buildhome/repo/pages/trades/user.vue?macro=true";
import { default as unauthorizedJow17xIT9LMeta } from "/opt/buildhome/repo/pages/unauthorized.vue?macro=true";
import { default as _91type_93HDlcX4enkrMeta } from "/opt/buildhome/repo/pages/user/[type].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: "adp-id",
    path: "/adp/:id()",
    meta: _91id_93zngLs5WzhxMeta || {},
    component: () => import("/opt/buildhome/repo/pages/adp/[id].vue")
  },
  {
    name: "adp-companion-id",
    path: "/adp/companion/:id()",
    meta: _91id_93ppZiX1Ty87Meta || {},
    component: () => import("/opt/buildhome/repo/pages/adp/companion/[id].vue")
  },
  {
    name: "adp-companion",
    path: "/adp/companion",
    component: () => import("/opt/buildhome/repo/pages/adp/companion/index.vue")
  },
  {
    name: "adp-compare",
    path: "/adp/compare",
    component: () => import("/opt/buildhome/repo/pages/adp/compare.vue")
  },
  {
    name: "adp-diff-compare",
    path: "/adp/diff/compare",
    component: () => import("/opt/buildhome/repo/pages/adp/diff/compare.vue")
  },
  {
    name: "adp-diff",
    path: "/adp/diff",
    component: () => import("/opt/buildhome/repo/pages/adp/diff/index.vue")
  },
  {
    name: "adp-draft",
    path: "/adp/draft",
    meta: draftfl9fbDYDBXMeta || {},
    component: () => import("/opt/buildhome/repo/pages/adp/draft.vue")
  },
  {
    name: "adp-hdraft",
    path: "/adp/hdraft",
    meta: hdraft34x77j4aTAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/adp/hdraft.vue")
  },
  {
    name: "adp",
    path: "/adp",
    component: () => import("/opt/buildhome/repo/pages/adp/index.vue")
  },
  {
    name: "adp-rookie_heatmap",
    path: "/adp/rookie_heatmap",
    component: () => import("/opt/buildhome/repo/pages/adp/rookie_heatmap.vue")
  },
  {
    name: "adp-sfb_rosters",
    path: "/adp/sfb_rosters",
    component: () => import("/opt/buildhome/repo/pages/adp/sfb_rosters.vue")
  },
  {
    name: "adp-sfb",
    path: "/adp/sfb",
    meta: sfbW88JVrvJCvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/adp/sfb.vue")
  },
  {
    name: "bestball-draftkings-adp",
    path: "/bestball/draftkings/adp",
    meta: adp6JABZoR7sbMeta || {},
    component: () => import("/opt/buildhome/repo/pages/bestball/draftkings/adp.vue")
  },
  {
    name: "bestball",
    path: "/bestball",
    component: () => import("/opt/buildhome/repo/pages/bestball/index.vue")
  },
  {
    name: "bestball-underdog-adp",
    path: "/bestball/underdog/adp",
    meta: adpyQrJUwsHAYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/bestball/underdog/adp.vue")
  },
  {
    name: "bestball-underdog-rosterGrid",
    path: "/bestball/underdog/rosterGrid",
    meta: rosterGridd4sF3sSfkCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/bestball/underdog/rosterGrid.vue")
  },
  {
    name: "bestball-underdog-rostership",
    path: "/bestball/underdog/rostership",
    component: () => import("/opt/buildhome/repo/pages/bestball/underdog/rostership/index.vue")
  },
  {
    name: "bestball-underdog-rostership-stacks",
    path: "/bestball/underdog/rostership/stacks",
    meta: stackszcdfD64sknMeta || {},
    component: () => import("/opt/buildhome/repo/pages/bestball/underdog/rostership/stacks.vue")
  },
  {
    name: "bestball-underdog-rostership-user",
    path: "/bestball/underdog/rostership/user",
    meta: useroWSaA6Fl0uMeta || {},
    component: () => import("/opt/buildhome/repo/pages/bestball/underdog/rostership/user.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93iiBzaDIhzjMeta || {},
    component: () => import("/opt/buildhome/repo/pages/blog/[...slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexnCitm8tluDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    component: () => import("/opt/buildhome/repo/pages/confirm.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "playerlist",
    path: "/playerlist",
    component: () => import("/opt/buildhome/repo/pages/playerlist.vue")
  },
  {
    name: "rankings-edit-id",
    path: "/rankings/edit/:id()",
    meta: _91id_93uGuSFTQCORMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rankings/edit/[id].vue")
  },
  {
    name: "rankings-edit",
    path: "/rankings/edit",
    meta: indexEcLBzCs9kDMeta || {},
    redirect: indexEcLBzCs9kDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/rankings/edit/index.vue")
  },
  {
    name: "rankings",
    path: "/rankings",
    component: () => import("/opt/buildhome/repo/pages/rankings/index.vue")
  },
  {
    name: "rankings-new-type",
    path: "/rankings/new/:type()",
    meta: _91type_9377Q4qIbNpQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rankings/new/[type].vue")
  },
  {
    name: "rankings-new",
    path: "/rankings/new",
    meta: indexpjG6xvESeoMeta || {},
    redirect: indexpjG6xvESeoMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/rankings/new/index.vue")
  },
  {
    name: "rankings-view-id",
    path: "/rankings/view/:id()",
    meta: _91id_93NclW2X0lqzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rankings/view/[id].vue")
  },
  {
    name: "rankings-view",
    path: "/rankings/view",
    component: () => import("/opt/buildhome/repo/pages/rankings/view/index.vue")
  },
  {
    name: "releases",
    path: "/releases",
    component: () => import("/opt/buildhome/repo/pages/releases.vue")
  },
  {
    name: "rosters-games",
    path: "/rosters/games",
    meta: gamesD8bed3utjzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rosters/games.vue")
  },
  {
    name: "rosters",
    path: "/rosters",
    meta: indexQCNzS30N5CMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rosters/index.vue")
  },
  {
    name: "rosters-lineups",
    path: "/rosters/lineups",
    meta: lineups1d1KnvwjMKMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rosters/lineups.vue")
  },
  {
    name: "rosters-players",
    path: "/rosters/players",
    meta: playersIIDDbF99AxMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rosters/players.vue")
  },
  {
    name: "rosters-suggestions-bye",
    path: "/rosters/suggestions/bye",
    meta: byet3bnrfTxPtMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rosters/suggestions/bye.vue")
  },
  {
    name: "rosters-suggestions-flex",
    path: "/rosters/suggestions/flex",
    meta: flexPtdPbvVo10Meta || {},
    component: () => import("/opt/buildhome/repo/pages/rosters/suggestions/flex.vue")
  },
  {
    name: "rosters-suggestions-injury",
    path: "/rosters/suggestions/injury",
    meta: injury9kbdYKodpBMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rosters/suggestions/injury.vue")
  },
  {
    name: "rosters-suggestions-start",
    path: "/rosters/suggestions/start",
    meta: startLiZiS83iQDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rosters/suggestions/start.vue")
  },
  {
    name: "rosters-test",
    path: "/rosters/test",
    meta: testMPkUTlm5CAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rosters/test.vue")
  },
  {
    name: "rosters-waivers-ownership",
    path: "/rosters/waivers/ownership",
    meta: ownershipJAeKP3gX4EMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rosters/waivers/ownership.vue")
  },
  {
    name: "rosters-waivers-trending",
    path: "/rosters/waivers/trending",
    meta: trendingbwEsSBFFZ8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/rosters/waivers/trending.vue")
  },
  {
    name: "stats",
    path: "/stats",
    meta: index9h9gSglSmHMeta || {},
    redirect: index9h9gSglSmHMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/stats/index.vue")
  },
  {
    name: "stats-player",
    path: "/stats/player",
    component: () => import("/opt/buildhome/repo/pages/stats/player.vue")
  },
  {
    name: "stats-rookie",
    path: "/stats/rookie",
    component: () => import("/opt/buildhome/repo/pages/stats/rookie.vue")
  },
  {
    name: "stats-team",
    path: "/stats/team",
    component: () => import("/opt/buildhome/repo/pages/stats/team.vue")
  },
  {
    name: "subscription-cancel",
    path: "/subscription/cancel",
    component: () => import("/opt/buildhome/repo/pages/subscription/cancel.vue")
  },
  {
    name: "subscription",
    path: "/subscription",
    component: () => import("/opt/buildhome/repo/pages/subscription/index.vue")
  },
  {
    name: "subscription-success",
    path: "/subscription/success",
    component: () => import("/opt/buildhome/repo/pages/subscription/success.vue")
  },
  {
    name: "trades-player",
    path: "/trades/player",
    meta: playerAZFRxHpl85Meta || {},
    component: () => import("/opt/buildhome/repo/pages/trades/player.vue")
  },
  {
    name: "trades-rostership",
    path: "/trades/rostership",
    component: () => import("/opt/buildhome/repo/pages/trades/rostership/index.vue")
  },
  {
    name: "trades-rostership-user",
    path: "/trades/rostership/user",
    meta: userxCy1ne5msRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/trades/rostership/user.vue")
  },
  {
    name: "trades-user",
    path: "/trades/user",
    meta: usercwLTbaiEanMeta || {},
    component: () => import("/opt/buildhome/repo/pages/trades/user.vue")
  },
  {
    name: "unauthorized",
    path: "/unauthorized",
    component: () => import("/opt/buildhome/repo/pages/unauthorized.vue")
  },
  {
    name: "user-type",
    path: "/user/:type()",
    meta: _91type_93HDlcX4enkrMeta || {},
    component: () => import("/opt/buildhome/repo/pages/user/[type].vue")
  }
]