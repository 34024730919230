export function mapTradeSide<T extends iTransactionPlayersSide>({ id_players_1 = [], id_players_2 = [] }: T, idSearch?: string) {
  const PlayerDataStore = usePlayerDataStore()
  let t1 = PlayerDataStore.genArrayPlayerData(id_players_1, 'id_pff', 'adp_adp')
  let t2 = PlayerDataStore.genArrayPlayerData(id_players_2, 'id_pff', 'adp_adp')
  const boxHeight = 38 + (47 * Math.max(t1.length, t2.length))
  if (idSearch) {
    const swapNeeded = t2.some(player => player.id_pff === idSearch)
    if (swapNeeded)
      [t1, t2] = [t2, t1]
  }
  return { id_players_1: t1, id_players_2: t2, boxHeight }
}

export function mapPickTradeSide({ id_players_1 = [], id_players_2 = [] }: iTransactionPick, idSearch?: string) {
  const players = {
    id_players_1: id_players_1.map(player => player.adp_id_pff).filter(Boolean) as string[],
    id_players_2: id_players_2.map(player => player.adp_id_pff).filter(Boolean) as string[],
  }
  const tradeData = mapTradeSide(players, idSearch)
  const tradeDataMap = new Map([
    ...tradeData.id_players_1.map(player => [player.id_pff, player] as [string, typeof player]),
    ...tradeData.id_players_2.map(player => [player.id_pff, player] as [string, typeof player]),
  ])

  const mergedPlayers1 = id_players_1.map((player) => {
    const tradePlayer = player?.adp_id_pff ? tradeDataMap.get(player.adp_id_pff) : undefined
    return tradePlayer ? { ...player, ...tradePlayer } : player
  })

  const mergedPlayers2 = id_players_2.map((player) => {
    const tradePlayer = player?.adp_id_pff ? tradeDataMap.get(player.adp_id_pff) : undefined
    return tradePlayer ? { ...player, ...tradePlayer } : player
  })

  return { id_players_1: mergedPlayers1, id_players_2: mergedPlayers2, boxHeight: tradeData.boxHeight }
}

export function processPlayerSide(players: iPlayer[]): iTradePlayerBox[] {
  return players.flatMap((player: iPlayer) => genTradePlayerBox(player)).filter(Boolean) as iTradePlayerBox[]
}

export function processPickSide(picks: iPickDefinition[]): iTradePickBox[] {
  return picks.flatMap((pick: iPickDefinition) => genTradePickBox(pick)).filter(Boolean) as iTradePickBox[]
}

export function parseLeagueInfo({ time_transaction, scoring_rec = 1, scoring_bonus_rec_te = 0, scoring_pass_td = 4 }: iTransaction): iTransactionLeagueMetadata {
  const ppr = scoring_rec === 0 ? 'STD' : (scoring_rec <= 0.5 ? '½PPR' : 'PPR')
  const tep = scoring_bonus_rec_te >= 0 ? 'TEP' : ''
  const pptd = `${scoring_pass_td}TD`
  const dateString = genDateString(time_transaction)
  return { ppr, tep, pptd, dateString }
}

export function parseTradeData(data: iTransactionPlayerRaw[], idSearch?: string): iTransactionPlayerFull[] {
  return data.map((transaction) => {
    const playerMetadata = mapTradeSide(transaction, idSearch)
    const leageMetadata = { leagueData: parseLeagueInfo(transaction) }
    const { id_players_1 = [], id_players_2 = [] } = playerMetadata
    const playerBoxes = {
      box1: processPlayerSide(id_players_1),
      box2: processPlayerSide(id_players_2),
    }
    return { ...transaction, ...leageMetadata, ...playerMetadata, ...playerBoxes }
  })
}

export function parsePickTradeData(data: iTransactionPick[], idSearch?: string) {
  return data.map((transaction) => {
    const playerMetadata = mapPickTradeSide(transaction, idSearch)
    const leageMetadata = { leagueData: parseLeagueInfo(transaction) }
    const playerBoxes = {
      box1: processPickSide(transaction.id_players_1!),
      box2: processPickSide(transaction.id_players_2!),
    }
    return { ...transaction, ...leageMetadata, ...playerMetadata, ...playerBoxes }
  })
}

export function genTradePickPickBox({ pickName, round }: iPickDetails): iTradePickBox {
  // const pickType = round <= 3 ? `FuturePK${round}` : 'default'
  const color = 'bg-primery-500' // TODO REPLACE WITH CORRECT COLOR
  // const color = `var(--bpp-${pickType}-color)`
  const hs = `/assets/headshots/2025-0${round}.webp`
  return { color, alt: pickName, hs, name: pickName, pos: 'PK', posColor: '#9FA1E8', tooltip: pickName }
}
export function genTradePickPlayerBox(player: iPlayer, { pickName }: iPickDetails, pick_perc: number): iTradePickBox {
  const color = getPercColor(pick_perc).join(', ')
  const posColor = usePositionColorClass(player)
  const { id_pff, id_pos = 'PK', id_name } = player
  const hs = `/assets/headshots/${id_pff}.webp`
  const tooltip = id_name ? `Equivalent to ${id_name}` : 'No Player Equivalent'
  return { color, alt: pickName, hs, name: pickName, pos: id_pos, posColor, tooltip }
}

export function genTradePlayerBox(player: iPlayer | string): iTradePlayerBox | undefined {
  const playerObj = typeof player === 'string' ? usePlayerDataStore().getPlayerData(player) : player
  if (!playerObj)
    return
  const { id_name = 'Unknown', adp_adp, id_pff = '0', pick_perc = 0, adp_posrank = '', id_pos = '' } = playerObj
  const adpLabel = adp_adp ? genLabelAdp(Math.round(adp_adp)) : ''
  const value_perc = pick_perc * 100
  const playerName = playerObj?.id_shortname || getPlayerShortName(playerObj)
  const boxcolor = usePositionColorClass(playerObj)
  const posrank = adp_posrank ? `${id_pos}${adp_posrank}` : ''
  const adpTip = adpLabel ? ` (adp: ${adpLabel} - ${posrank})` : ''
  const tip = `${id_name}${adpTip}`
  return { id_name, id_pff, id_pos, boxcolor, adpLabel, playerName, tip, value_perc, posrank }
}

export function getPlayerShortName({ id_pos, id_firstname, id_lastname, id_name }: iPlayer): string {
  return (id_pos !== 'PK' && id_firstname && id_firstname?.length > 4 ? `${id_firstname[0]}. ${id_lastname}` : id_name) || 'Unknown'
}

export function genTradePickBox({ id_pff, adp_id_pff, pick_perc }: iPickDefinition) {
  const pickData = parsePickId(id_pff)
  if (!pickData)
    return
  const adp_player = adp_id_pff ? usePlayerDataStore().getPlayerData(adp_id_pff, 'id_pff') : undefined
  if (!adp_player)
    return genTradePickPickBox(pickData)
  return genTradePickPlayerBox(adp_player, pickData, pick_perc)
}

export function parsePickId(playerId: string | undefined, currentSeason = 2023): iPickDetails | undefined {
  if (!playerId)
    return

  const match = playerId.match(/(?<season>\d{4})(?<round>\d{2})(?<pick>\d{2})?/)
  if (!match?.groups)
    return
  const { season, round, pick } = match.groups
  const parsedSeason = Number(season)
  const parsedRound = Number(round)
  const parsedPick = pick ? Number(pick) : undefined
  if (Number.isNaN(parsedSeason) || Number.isNaN(parsedRound) || (parsedPick && Number.isNaN(parsedPick)))
    return
  const pickName = parsedPick === undefined || parsedPick === 0 || parsedSeason > currentSeason
    ? `${parsedSeason} ${getOrdinalNumber(parsedRound)}`
    : `${round.padStart(2, '0')}.${pick.padStart(2, '0')}`
  const id_player = `${season}${round}${parsedPick ? pick : ''}`
  return { season: parsedSeason, round: parsedRound, pick: parsedPick, pickName, id_player }
}

export function checkTradePlayerErrors(player?: string | string[]) {
  if (!player || player.length === 0)
    throw new CustomError('No player Detected!', 'Please provide a player to search for.', 'No player Detected')
}

export function checkTradeUserErrors(user?: string) {
  if (!user || user.length === 0)
    throw new CustomError('No User Detected!', 'Please provide a user to search for.', 'No User Detected')

  if (user.toLowerCase() === 'adeiko')
    throw new CustomError('Private Vault Detected!', 'Oops! You\'ve hit a restricted zone. This area is reserved for my creator eyes only.', 'Invalid User')
}

// export function getPlayerBoxColor({ id_pos, id_name, id_adp }: iPlayer): string {
//   if (id_pos !== 'PK')
//     return `var(--bpp-${id_pos}-color)`
//   const round = Number(id_name?.slice(id_adp ? -4 : -3, id_adp ? -3 : -2))
//   const colorKey = (!round || round > 3)
//     ? 'default'
//     : `${id_adp ? 'PK' : 'FuturePK'}${round}`
//   return `var(--bpp-${colorKey}-color)`
// }

// export function genPlayerInfo(transaction: iTransactionPlayer) {
//   const { id_players_1 = [], id_players_2 = [] } = transaction
//   return {
//     ...transaction,
//     id_players_1: processPlayerSide(id_players_1),
//     id_players_2: processPlayerSide(id_players_2),
//   }
// }

// function mapTradeSide<T extends iTransactionPlayersSide>({ id_players_1 = [], id_players_2 = [] }: T, idSearch?: string) {
//   const PlayerDataStore = usePlayerDataStore()
//   let t1 = PlayerDataStore.genArrayPlayerData(id_players_1, 'id_pff', 'adp_adp')
//   let t2 = PlayerDataStore.genArrayPlayerData(id_players_2, 'id_pff', 'adp_adp')
//   const boxHeight = 38 + (47 * Math.max(t1.length, t2.length))
//   if (idSearch) {
//     const swapNeeded = t2.some(player => player.id_pff === idSearch)
//     if (swapNeeded) {
//       // movePlayerToFront(t2, idSearch);
//       [t1, t2] = [t2, t1]
//     }
//     // else {
//     //   movePlayerToFront(t1, idSearch)
//     // }
//   }
//   // TODO: NOT CORRECTLY MOVING PLAYER IN FRONT

//   return { id_players_1: t1, id_players_2: t2, boxHeight }
// }
