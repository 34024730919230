import { isPostDraft } from './TimeUtils'

export async function fetchSleeperData<T>(url: string) {
  try {
    return await $fetch<T>(`https://api.sleeper.app/v1/${url}`)
  }
  catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function fetchSleeperRestictedData<T>(url: string) {
  try {
    return await $fetch<T>(`https://api.sleeper.app/${url}`)
  }
  catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function fetchSleeperWeekGames(season: number, week: number): Promise<Array<iSleeperGame>> {
  return await fetchSleeperRestictedData<iSleeperGame[]>(`scores/nfl/regular/${season}/${week}`)
}

export async function fetchSleeperDraftPicksDraft(draftId: string) {
  return await fetchSleeperData<iSleeperDraftPick[]>(`draft/${draftId}/picks`)
}

export async function fetchSleeperLeagueDraftPicks(leagueId: string): Promise<Array<iSleeperDraftPick> | undefined> {
  const leagueData = await fetchSleeperData<iSleeperLeagueSettings>(`league/${leagueId}`)
  const { draft_id } = leagueData || {}
  return draft_id ? await getDraftPickData(draft_id) : undefined
}

export async function fetchSleeperState(): Promise<iSleeperState> {
  return await fetchSleeperData<iSleeperState>('state/nfl')
}

export async function fetchSleeperDraftSettings(draftId?: string): Promise<iSleeperDraft | undefined> {
  return draftId ? await fetchSleeperData<iSleeperDraft>(`draft/${draftId}`) : undefined
}

export async function fetchSleeperTrendingPlayers(type: 'add' | 'drop' = 'add', lookback_hours: number = 24, limit: number = 10): Promise<Array<iSleeperTrendingPlayer>> {
  return await fetchSleeperData<Array<iSleeperTrendingPlayer>>(`/players/nfl/trending/${type}?lookback_hours=${lookback_hours}&limit=${limit}`)
}
export async function fetchSleeperLeagueSettings(leagueId?: string): Promise<iSleeperLeague | undefined> {
  return leagueId ? await fetchSleeperData<iSleeperLeague>(`league/${leagueId}`) : undefined
}

export async function fetchSleeperLeagueUsers(leagueId?: string): Promise<Array<iSleeperLeagueUser> | undefined> {
  return leagueId ? await fetchSleeperData<Array<iSleeperLeagueUser>>(`league/${leagueId}/users`) : undefined
}

export async function fetchSleeperLeagueMatchups(leagueId?: string, week: number = 1): Promise<Array<iSleeperMatchup> | undefined> {
  return leagueId ? await fetchSleeperData<Array<iSleeperMatchup>>(`league/${leagueId}/matchups/${week}`) : undefined
}

export async function fetchSleeperWeekInjuries(week: number = 1, season: number = new Date().getFullYear()) {
  return await fetchSleeperRestictedData<iSleeperInjuries>(`players/nfl/regular/${season}/${week}/injuries`)
}

export async function fetchSleeperWeekStartRate(type: number = 2, week: number = 1, season: number = new Date().getFullYear()) {
  return await fetchSleeperRestictedData<iSleeperStartRate>(`players/nfl/research/regular/${season}/${week}?league_type=${type}`)
}

export async function fetchSleeperLeagueRosters(leagueId?: string): Promise<Array<iSleeperRosters> | undefined> {
  return leagueId ? await fetchSleeperData<Array<iSleeperRosters>>(`league/${leagueId}/rosters`) : undefined
}

export async function fetchSleeperUserId(username?: string): Promise<iSleeperUser | undefined> {
  // if (username && /^\d+$/.test(username))
  //   return username
  return username ? await fetchSleeperData<iSleeperUser>(`user/${username}`) : undefined
}

export async function fetchSleeperUserLeagues(userId?: string, season: number = new Date().getFullYear()): Promise<Array<iSleeperLeague> | undefined> {
  return userId ? await fetchSleeperData<Array<iSleeperLeague>>(`user/${userId}/leagues/nfl/${season}`) : undefined
}

export async function fetchSleeperUserDrafts(userId?: string, season: number = new Date().getFullYear()): Promise<Array<iSleeperLeague> | undefined> {
  return userId ? await fetchSleeperData<Array<iSleeperLeague>>(`user/${userId}/drafts/nfl/${season}`) : undefined
}

export async function getDraftPickData(id: string) {
  try {
    return await fetchSleeperDraftPicksDraft(id)
  }
  catch {
    return await fetchSleeperLeagueDraftPicks(id)
  }
}
export function mutateDraftPicksKickers<T extends { player_id: string, metadata: { position: string } }>(draftPicksData: Array<T | iSleeperDraftPick>, season: number) {
  let round = 1
  let slot = 1
  for (const pick of draftPicksData) {
    const position = pick.metadata?.position
    if (position === 'K' || position === 'P') {
      pick.player_id = `${season}${round.toString().padStart(2, '0')}${slot.toString().padStart(2, '0')}`
      pick.metadata.position = 'PK'
      slot++
      if (slot > 12) {
        slot = 1
        round++
      }
    }
  }
}

export async function getBaseBoardAdpPlayers(type: iBoardVisualType): Promise<Array<iPlayer>> {
  const [start_date, end_date] = genDateRangeLastMonth().map(date => date.toSeconds())
  const postDraft = isPostDraft()
  const query = { end_date, start_date, type: type === 'rookies' ? 'rookies_std' : postDraft ? 'rookies' : 'simple' }
  const players = await useBppSimpleFetch<Array<iPlayer>>('adp/adp', { query })
  usePlayerDataStore().patchPlayerMetrics(players)
  patchAdpMetrics(players)
  return players
}

function calculatePositionRostershipStats(dataset: number[], total: number) {
  const position_count = dataset.reduce((a, b) => a + b, 0)
  const position_per = position_count / total
  const perc = normalizeValue(position_per, 0, 0.5, true)
  return {
    position_count,
    position_count_perc: perc,
    position_per,
    position_per_perc: perc,
  }
}

function calculatePositionRostership(dataset: Array<iPlayerRoster>): iRostershipPositionInsights {
  const playerCount: { [key: string]: { position_count: number, position_count_perc: number, position_per: number, position_per_perc: number } } = {}
  const totalPlayers = dataset.map(player => player.roster_count)
  const total = totalPlayers.reduce((a, b) => a + b, 0)
  playerCount.total = calculatePositionRostershipStats(totalPlayers, total)
  for (const position of ['QB', 'RB', 'WR', 'TE']) {
    const playerDataset = dataset.filter(player => player.id_pos === position).map(player => player.roster_count)
    playerCount[position] = calculatePositionRostershipStats(playerDataset, total)
  }
  return playerCount
}
function extractDataset(data: iRostershipPositionInsights, title: string, isPercent: boolean) {
  const newInsight: { [key: string]: number | string } = {}
  for (const position of ['QB', 'RB', 'WR', 'TE', 'total']) {
    newInsight[position] = isPercent ? data[position]?.position_per : data[position]?.position_count
    newInsight[`${position}_perc`] = data[position]?.position_count_perc
  }
  newInsight.title = title
  return newInsight
}

export function calculateBoardInsights(dataset: Array<iPlayerRoster>, isPercent: boolean) {
  return [
    extractDataset(calculatePositionRostership(dataset.filter(player => ((player?.adp_adp || 0) > 0) && ((player?.adp_adp || 0) <= 24))), '1-24 ADP', isPercent),
    extractDataset(calculatePositionRostership(dataset.filter(player => ((player?.adp_adp || 0) > 0) && ((player?.adp_adp || 0) <= 50))), '1-50 ADP', isPercent),
    extractDataset(calculatePositionRostership(dataset.filter(player => ((player?.adp_adp || 0) > 0) && ((player?.adp_adp || 0) <= 100))), '1-100 ADP', isPercent),
    extractDataset(calculatePositionRostership(dataset.filter(player => ((player?.adp_adp || 0) > 50) && ((player?.adp_adp || 0) <= 100))), '50-100 ADP', isPercent),
    extractDataset(calculatePositionRostership(dataset.filter(player => ((player?.adp_adp || 0) > 100) && ((player?.adp_adp || 0) <= 300))), '100-300 ADP', isPercent),
    extractDataset(calculatePositionRostership(dataset.filter(player => ((player?.phy_age || 0) <= 26 && (player?.adp_adp || 0) > 0) && ((player?.adp_adp || 0) <= 100))), '<26yo 1-100', isPercent),
    extractDataset(calculatePositionRostership(dataset.filter(player => ((player?.phy_age || 0) > 26 && (player?.adp_adp || 0) > 0) && ((player?.adp_adp || 0) <= 100))), '>26to 1-100', isPercent),
  ]
}

export function parseSleeperRosterPlayers(leagueRosters: { [key: string]: iSleeperRosters }): iPlayerRoster[] {
  const playerCount: { [key: string]: { count: number, leagues: string[] } } = {}
  Object.values(leagueRosters).forEach((roster) => {
    if (roster.players) {
      roster.players.forEach((playerId) => {
        if (!playerCount[playerId])
          playerCount[playerId] = { count: 0, leagues: [] }

        playerCount[playerId].count++
        playerCount[playerId].leagues.push(roster.league_id)
      })
    }
  })
  const totalLeagues = Object.keys(leagueRosters).length
  const maxCount = Math.max(...(Object.values(playerCount))?.map(player => player.count) || [0])
  const PlayerDataStore = usePlayerDataStore()
  const players = Object.entries(playerCount).map(([id, data]) => {
    const player = PlayerDataStore.getPlayerData(id)
    const percValue = normalizeValue(data.count, 0, maxCount, true)
    return {
      ...player,
      id_sleeper: id,
      roster_per: roundNumber((data.count / totalLeagues), 4),
      roster_per_perc: percValue,
      roster_count: data.count,
      roster_count_perc: percValue,
      roster_leagues: data.leagues,
    }
  }).sort(sortByAttribute('roster_per', 'desc'))
  patchAdpMetrics(players)
  return players
}

export async function fetchSleeperAllLeagueRosters(leagueIds: Array<string>) {
  const leagueRosters: { [key: string]: iSleeperRosters[] } = {}
  const errors: { [key: string]: string } = {}

  const leaguePromises = leagueIds.map(async (leagueId) => {
    try {
      const leagueRoster = await fetchSleeperLeagueRosters(leagueId)
      if (!leagueRoster)
        throw new Error('No roster data found')

      leagueRosters[leagueId] = leagueRoster
    }
    catch (error: unknown) {
      if (error instanceof Error)
        errors[leagueId] = error.message
    }
  })

  await Promise.allSettled(leaguePromises)
  return { leagueRosters, errors }
}

export async function fetchSleeperAllLeagueUsers(leagueIds: Array<string>) {
  const leagueUsers: { [key: string]: iSleeperLeagueUser[] } = {}
  const errors: { [key: string]: string } = {}

  const leaguePromises = leagueIds.map(async (leagueId) => {
    try {
      const leagueUser = await fetchSleeperLeagueUsers(leagueId)
      if (!leagueUser)
        throw new Error('No roster data found')

      leagueUsers[leagueId] = leagueUser
    }
    catch (error: unknown) {
      if (error instanceof Error)
        errors[leagueId] = error.message
    }
  })

  await Promise.allSettled(leaguePromises)
  return { leagueUsers, errors }
}

export function filterSleeperRostersByUserId(leagueRosters: { [key: string]: iSleeperRosters[] }, userId: string) {
  const filteredRosters: { [key: string]: iSleeperRosters } = {}

  for (const leagueId in leagueRosters) {
    const roster = leagueRosters[leagueId].filter(roster => (roster.owner_id === userId) || ((roster?.co_owners || []).includes(userId)))
    if (roster.length > 0)
      filteredRosters[leagueId] = roster[0]
  }

  return filteredRosters
}

// export async function fetchSleeperAllLeagueRosters(leagueIds: Array<string>, userId: string) {
//   const leagueRosters: { [key: string]: iSleeperRosters } = {}
//   const errors: { [key: string]: string } = {}

//   const leaguePromises = leagueIds.map(async (leagueId) => {
//     try {
//       const leagueRoster = await fetchSleeperLeagueRosters(leagueId)
//       if (!leagueRoster)
//         throw new Error('No roster data found')

//       const roster = leagueRoster.filter(roster => roster.owner_id === userId)
//       if (roster.length > 0)
//         leagueRosters[leagueId] = roster[0]
//     }
//     catch (error: unknown) {
//       if (error instanceof Error)
//         errors[leagueId] = error.message
//     }
//   })

//   await Promise.allSettled(leaguePromises)
//   return { leagueRosters, errors }
// }

export function parseSleeperRosterPositions(positions: string[]): Record<icDraftPositions, number> {
  const positionCounts: Record<icDraftPositions, number> = {
    QB: 0,
    RB: 0,
    WR: 0,
    TE: 0,
    FLEX: 0,
    SFLEX: 0,
    BN: 0,
  }

  for (const pos of positions) {
    if (pos in positionCounts)
      positionCounts[pos as icDraftPositions]++
    else if (pos === 'REC_FLEX' || pos === 'WRRB_FLEX')
      positionCounts.FLEX++
    else if (pos === 'SUPER_FLEX')
      positionCounts.SFLEX++
  }

  return positionCounts
}

export function generatecDraftTeam(team: number): icDraftTeam {
  return {
    name: `Team ${team + 1}`,
    color: 'transparent',
    team_id: team,
  }
}
export function parseSleeperDraftTeams(draftData: iSleeperDraft, leagueUsers: Array<iSleeperLeagueUser>): icDraftTeam[] {
  const { draft_order } = draftData
  const userMap = new Map(leagueUsers.map(user => [user.user_id, user]))
  return Object.entries(draft_order).map(([id, team_id]) => {
    const { display_name, avatar } = userMap.get(id) || {}
    return { name: display_name || `Team ${team_id + 1}`, avatar, team_id, color: 'transparent' }
  }).sort(sortByAttribute('team_id'))
}

export function parseSleeperDraftSettings(draftData: iSleeperDraft) {
  const { settings, type, metadata } = draftData
  return {
    teams: settings.teams,
    slots: {
      QB: settings?.slots_qb || 0,
      RB: settings?.slots_rb || 0,
      WR: settings?.slots_wr || 0,
      TE: settings?.slots_te || 0,
      FLEX: (settings?.slots_flex || 0) + (settings?.slots_wrrb_flex || 0) + (settings?.slots_rec_flex || 0),
      SFLEX: settings.slots_super_flex || 0,
      BN: settings.slots_bn || 0,
    },
    rounds: settings.rounds,
    sorting: settings?.reversal_round === 3 ? 'rr' : type,
    name: metadata.name,
    // type: 'startup',
  }
}

export function extractSleeperLeaguesRanges(dataset: Array<iSleeperLeague>): Partial<iSleeperLeagueRanges> {
  return {
    pptd: extractMinMax(dataset.map(l => l.scoring_settings?.pass_td || 0)),
    ppr: extractMinMax(dataset.map(l => l.scoring_settings?.rec || 0)),
    tep: extractMinMax(dataset.map(l => l.scoring_settings?.bonus_rec_te || 0)),
    teams: extractMinMax(dataset.map(l => l.total_rosters || 0)),
    starters: extractMinMax(dataset.map(l => l.roster_positions?.filter(p => p !== 'BN').length || 0)),
    roster: extractMinMax(dataset.map(l => l.roster_positions?.length || 0)),
    // bestBall: new Set(dataset.map(l => l.settings.best_ball)).size,
    // superflex: new Set(dataset.map(l => l.roster_positions?.filter(p => ['QB', 'SUPER_FLEX'].includes(p)).length || 0)).size,
    leagueNames: dataset.reduce((acc, l) => {
      acc[l.league_id] = true
      return acc
    }, {} as { [key: string]: boolean }),
  }
}

export function getSleeperFilteredLeagues(baseLeagues: iSleeperLeague[], configuration: iSleeperLeagueRanges): Array<string> {
  const filteredLeagues: Array<string> = []
  for (const league of baseLeagues) {
    if (isSleeperLeagueValid(league, configuration)) {
      filteredLeagues.push(league.league_id)
    }
  }
  return filteredLeagues
}

export function isSleeperLeagueValid(league: iSleeperLeague, configuration: iSleeperLeagueRanges): boolean {
  const { pptd, ppr, tep, teams, starters, roster, bestball, twoTE, superflex, leagueNames } = configuration
  const { league_id, total_rosters, roster_positions = [], scoring_settings, settings } = league
  const { pass_td = 0, rec = 0, bonus_rec_te = 0 } = scoring_settings
  const { best_ball } = settings
  const leagueSF = roster_positions.filter(p => ['QB', 'SUPER_FLEX'].includes(p)).length
  const leagueTwoTE = roster_positions.filter(p => p === 'TE').length
  const leagueStarters = roster_positions.filter(p => p !== 'BN').length
  const leagueRoster = roster_positions.length

  return (leagueNames[league_id])
    && ((superflex === 'All') || (superflex === 'Yes' ? leagueSF >= 2 : leagueSF < 2))
    && ((bestball === 'All') || (bestball === 'Yes' ? !!best_ball : !best_ball))
    && ((twoTE === 'All') || (twoTE === 'Yes' ? leagueTwoTE >= 2 : leagueTwoTE < 2))
    && isValueInRange(pass_td, pptd)
    && isValueInRange(rec, ppr)
    && isValueInRange(bonus_rec_te, tep)
    && isValueInRange(total_rosters, teams)
    && isValueInRange(leagueStarters, starters)
    && isValueInRange(leagueRoster, roster)
}
